import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PageHero from "../components/common/page-hero"

const ResumeServicePage = () => (
  <Layout>
    <Seo title="Resume Service" />
    <PageHero title="Resume Service" />

    <div className="bg-gray-100">
      <div className="w-11/12 max-w-screen-lg mx-auto pt-16 pb-20">
        <p className="text-lg mb-6">HireLive is the industry leader for Sales, Retail, Marketing, Customer Service, Warehouse and Management recruiting services. Whether you want your own Open House, a Text or Email Marketing Campaign, Contingency Search or Resumes to follow up with directly, HireLive is here to help you with your recruiting needs. Due to our extensive marketing efforts and our partnerships with the major job boards, HireLive adds more than 600,000 resumes to our proprietary database each year, which brings the current total to over 6 million resumes in our ATS. With such an extensive database of current, active and passive candidates, plus our partnerships with Major Job Boards, HireLive is perfectly placed to help you with a Resume or Ad Posting Package. This is a very effective way to get qualified candidate resumes directly to you and your recruiting team quickly and affordably.</p>
        <h2 className="text-2xl mb-8">Here is how a HireLive “Resume or Ad Posting Package” Works:</h2>
        <p className="text-lg mb-6"><b>Step 1:</b> Fill out your Job Order Form with location, key words, radius, job title, job description, etc.</p>
        <p className="text-lg mb-6"><b>Step 2:</b> We will source from our proprietary database for you based on your key words, location, titles, etc. You will have these resumes in less than 48 hours.</p>
        <p className="text-lg mb-6"><b>Step 3:</b> We can post your job on major job boards for 2 weeks.</p>
        <p className="text-lg mb-6"><b>Step 4:</b> These resumes will be emailed to you after week one and again after week 2.</p>
        <p className="text-lg mb-6"><b>Step 5:</b> Those resumes will be emailed over for you to keep and follow up with.</p>
        <h2 className="text-2xl mb-8">Benefits of a HireLive “Resume or Ad Posting Package”</h2>
        <ul className="mb-6 list-inside list-disc">
          <li className="text-lg ml-3 mb-6">This is an extremely inexpensive way to get many resumes to help fill your positions.</li>
          <li className="text-lg ml-3 mb-6">HireLive can have your ads posted and resumes sourced for you in less than 48 hours.</li>
          <li className="text-lg ml-3 mb-6">You will have access to both active candidates applying and candidates that may never have applied to your ad.</li>
          <li className="text-lg ml-3 mb-6">Flat fee, you can make as many hires as you like from all the resumes you received.</li>
          <li className="text-lg ml-3 mb-6">You can add these resumes to your own ATS and continue to follow up with them.</li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default ResumeServicePage